import React, { FC } from 'react';
import { ROUTES } from '../../utils/routes';
import styled from 'styled-components';
import { rem } from 'polished';
import LocaleLink from '../LocaleLink/LocaleLink';
import { ContentfulAktualnosciWpis } from '../../model/generated/graphql';
import { pxToRem } from '../../utils/pxToRem';
import { GatsbyImage } from 'gatsby-plugin-image';

type RecentPostProps = {
    node: ContentfulAktualnosciWpis;
};
export const RecentPost: FC<RecentPostProps> = ({ node }) => {
    const { title, dataPublikacji, slug } = node;
    return (
        <RecentPostWrapper>
            <RecentPostLink to={`${ROUTES.NEWS}/${slug}`}>
                <RecentPostDate>{dataPublikacji}</RecentPostDate>
                <RecentPostHeader>{title}</RecentPostHeader>
            </RecentPostLink>
        </RecentPostWrapper>
    );
};

const RecentPostWrapper = styled.li`
    margin-bottom: ${rem(25)};
`;

const RecentPostLink = styled(LocaleLink)`
    color: var(--color-primary);
    &:hover h3 {
        color: var(--color-secondary);
    }
`;

const DownloadLink = styled.a`
    font-weight: 600;
    color: var(--color-primary);
    &:hover {
        color: var(--color-secondary);
    }
`;

const RecentPostDate = styled.p`
    margin: 0;
`;

const RecentPostHeader = styled.h3`
    margin: 0;
`;

export const SectionTitle = styled.h3`
    font-size: ${rem(22)};
    margin-top: ${rem(32)};
`;

export const ArticleLayout = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: ${rem(50)};
    margin-top: ${rem(50)};

    @media (max-width: ${rem(800)}) {
        grid-template-columns: 1fr;
    }

    & h4 + ul {
        margin-top: ${rem(-12)};
    }
    margin-bottom: ${pxToRem(100)};
`;

export const ArticleImg = styled(GatsbyImage)`
    border-radius: ${rem(25)};
`;

export const GalleryImg = styled(GatsbyImage)`
    border-radius: ${rem(25)};
`;
export const GalleryWrapper = styled.div`
    display: grid;
    gap: ${rem(32)};
`;

export const RecentPostsHeader = styled.h2`
    margin-top: 0;
`;

export const Aside = styled.aside`
    padding: ${rem(24)};
    background-color: var(--gray-light);
    border-radius: ${rem(25)};
`;
