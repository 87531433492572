import React, { FC, Fragment, useContext } from 'react';
import { graphql } from 'gatsby';
import {
    ContentfulAktualnosciWpisConnection,
    ContentfulPageCennik,
    ContentfulPageKontakt,
    ContentfulPageRehabilitacja,
    ContentfulRehabilitacjaWpis,
} from '../model/generated/graphql';
import { PageContentWrapper } from '../components/PageContentWrapper/PageContentWrapper';
import { Container } from '../components/Container/Container';
import { PageRow } from '../components/PageRow/PageRow';
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import LocaleLink from '../components/LocaleLink/LocaleLink';
import { ROUTES } from '../utils/routes';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { easeInOut, rem } from 'polished';
import PhoneIcon from '../../static/icons/call_black_24dp.svg';
import EmailIcon from '../../static/icons/email_black_24dp.svg';
import { PageTransition } from '../components/PageTransition/PageTransition';
import { pxToRem } from '../utils/pxToRem';
import Seo from '../components/Seo/Seo';
import LocaleContext from '../context/Locale';
import { ArticleLayout } from '../components/News/NewsTemplateComponents';

type Props = {
    data: {
        contentfulPageRehabilitacja: ContentfulPageRehabilitacja;
        contentfulRehabilitacjaWpis: ContentfulRehabilitacjaWpis;
        allContentfulRehabilitacjaWpis: ContentfulAktualnosciWpisConnection;
        contentfulPageCennik: ContentfulPageCennik;
        contentfulPageKontakt: ContentfulPageKontakt;
    };
};

export const ServicePageTemplate: FC<Props> = ({ data }) => {
    const { allContentfulRehabilitacjaWpis, contentfulRehabilitacjaWpis, contentfulPageCennik, contentfulPageKontakt } =
        data;

    const procedureLabel = (bundleCapacity) => {
        switch (bundleCapacity) {
            case '10':
                return contentfulPageCennik.pakietDziesiciuZabiegw;
            case '5':
                return contentfulPageCennik.pakietPiciuZabiegw;
            default:
                return contentfulPageCennik.pojedynczyZabieg;
        }
    };
    const { activeLocale } = useContext(LocaleContext);

    return (
        <>
            <Seo locale={activeLocale} seo={{ title: contentfulRehabilitacjaWpis.title }} />
            <PageContentWrapper title={contentfulRehabilitacjaWpis.titleExtended}>
                <Container>
                    <PageTransition>
                        <PageRow>
                            <ArticleLayout>
                                <article>
                                    {contentfulRehabilitacjaWpis.image && (
                                        <ArticleImg image={contentfulRehabilitacjaWpis.image.gatsbyImageData} alt="" />
                                    )}
                                    <div>
                                        {renderRichText(
                                            contentfulRehabilitacjaWpis.description as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                        )}
                                    </div>
                                </article>
                                <div>
                                    <Aside>
                                        <RecentPostsHeader>{data.contentfulPageCennik.title}</RecentPostsHeader>
                                        {renderRichText(
                                            data.contentfulPageCennik
                                                .pricingWidgetDescription as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                        )}
                                        <Hr />
                                        {contentfulRehabilitacjaWpis.pricing &&
                                        contentfulRehabilitacjaWpis.pricing.length > 1
                                            ? contentfulRehabilitacjaWpis.pricing.map((el) => {
                                                  return (
                                                      <Fragment key={el.contentful_id}>
                                                          <p>
                                                              <strong>
                                                                  {el.label} {el.duration}:
                                                              </strong>
                                                          </p>
                                                          <ul>
                                                              {el.priceBundle && (
                                                                  <li>
                                                                      <p>
                                                                          <strong>
                                                                              {el.priceBundle}{' '}
                                                                              {contentfulPageCennik.currency}
                                                                          </strong>{' '}
                                                                          {procedureLabel(el.bundleCapacity)}
                                                                      </p>
                                                                  </li>
                                                              )}
                                                              <li>
                                                                  <p>
                                                                      <strong>
                                                                          {el.priceUnit} {contentfulPageCennik.currency}
                                                                      </strong>{' '}
                                                                      {contentfulPageCennik.pojedynczyZabieg}
                                                                  </p>
                                                              </li>
                                                          </ul>
                                                      </Fragment>
                                                  );
                                              })
                                            : contentfulRehabilitacjaWpis.pricing.map((el) => {
                                                  return (
                                                      <Fragment key={el.contentful_id}>
                                                          {el.priceBundle && (
                                                              <p>
                                                                  <strong>
                                                                      {el.priceBundle} {contentfulPageCennik.currency}
                                                                  </strong>{' '}
                                                                  {procedureLabel(el.bundleCapacity)}
                                                              </p>
                                                          )}
                                                          <p>
                                                              <strong>
                                                                  {el.priceUnit} {contentfulPageCennik.currency}
                                                              </strong>{' '}
                                                              {contentfulPageCennik.pojedynczyZabieg}
                                                          </p>
                                                      </Fragment>
                                                  );
                                              })}
                                    </Aside>

                                    <Aside>
                                        <RecentPostsHeader>
                                            {data.contentfulPageRehabilitacja.scheduleVisitLabel}
                                        </RecentPostsHeader>
                                        <p>{data.contentfulPageRehabilitacja.scheduleVisitDescription}</p>
                                        {/*{data.contentfulRehabilitacjaWpis.booksy && (*/}
                                        {/*    <p>*/}
                                        {/*        <CtaLink*/}
                                        {/*            href={*/}
                                        {/*                'https://booksy.com/pl-pl/158309_mag-med-rehabilitacja-fizjoterapia_fizjoterapia_23280_lodz'*/}
                                        {/*            }*/}
                                        {/*            target={'_blank'}*/}
                                        {/*        >*/}
                                        {/*            Rezerwacja on-line*/}
                                        {/*        </CtaLink>*/}
                                        {/*    </p>*/}
                                        {/*)}*/}
                                        <SidebarLink href={`tel:${contentfulPageKontakt.email}`}>
                                            <EmailIcon width={18} fill="#36355D" />
                                            {contentfulPageKontakt.email}
                                        </SidebarLink>
                                        <SidebarLink href={`tel:${contentfulPageKontakt.phone1Number}`}>
                                            <PhoneIcon width={18} fill="#36355D" />
                                            {contentfulPageKontakt.phone1Number}
                                        </SidebarLink>
                                    </Aside>

                                    <Aside>
                                        <RecentPostsHeader>Pozostałe zabiegi</RecentPostsHeader>
                                        <AsideServices>
                                            {allContentfulRehabilitacjaWpis.edges.map(({ node }) => {
                                                if (node.contentful_id !== contentfulRehabilitacjaWpis.contentful_id) {
                                                    return (
                                                        <AsideServicesItem key={node.contentful_id}>
                                                            <LocaleLink to={`${ROUTES.REHABILITACJA}/${node.slug}`}>
                                                                {node.title}
                                                            </LocaleLink>
                                                        </AsideServicesItem>
                                                    );
                                                }
                                            })}
                                        </AsideServices>
                                    </Aside>
                                </div>
                            </ArticleLayout>
                        </PageRow>
                    </PageTransition>
                </Container>
            </PageContentWrapper>
        </>
    );
};

export default ServicePageTemplate;

export const CtaLink = styled.a`
    background-color: var(--color-primary);
    border-color: var(--color-primary);

    color: var(--color-white);
    max-width: 100%;
    display: block;
    padding: ${pxToRem(14)} ${pxToRem(12)};
    border-radius: ${pxToRem(25)};
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    transition: background-color ${easeInOut('quad')} 300ms, color ${easeInOut('quad')} 300ms;
    &:hover {
        background-color: var(--color-white);
        color: var(--color-primary);
    }
`;

const ArticleImg = styled(GatsbyImage)`
    border-radius: ${rem(25)};
`;

export const Aside = styled.div`
    padding: ${rem(24)};
    background-color: var(--gray-light);
    border-radius: ${rem(25)};
    margin-bottom: ${rem(50)};

    & p + ul {
        margin-top: ${pxToRem(-10)};
    }
    & li > p {
        margin-top: ${pxToRem(5)};
        margin-bottom: ${pxToRem(5)};
    }
`;

export const RecentPostsHeader = styled.h2`
    margin-top: 0;
`;

const Hr = styled.hr`
    border-color: white;
    background-color: white;
    border-style: solid;
`;

export const SidebarLink = styled.a`
    color: var(--color-text-primary);
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: ${rem(8)} 0;
    margin-bottom: ${rem(4)};
    transition: color ease-in-out 300ms;

    &:hover {
        color: var(--color-text-secondary);
    }

    &:hover svg {
        fill: var(--color-text-secondary);
    }

    & svg {
        transition: fill ease-in-out 300ms;
        margin-right: ${rem(8)};
    }
`;

export const AsideServices = styled.ul`
    padding: 0;
`;

export const AsideServicesItem = styled.li`
    list-style: none;
    font-size: 1rem;
    padding: ${rem(6)} 0;
    margin-bottom: ${rem(6)};

    & a {
        color: var(--color-text-primary);
        transition: color ease-in-out 300ms;
        font-weight: 600;
    }

    &:hover a {
        color: var(--color-text-secondary);
    }
`;

export const pageQuery = graphql`
    query Service($node_locale: String, $id: String) {
        contentfulPageRehabilitacja(
            contentful_id: { eq: "5tU4gA9JdKioNju1gHTbzE" }
            node_locale: { eq: $node_locale }
        ) {
            id
            title
            scheduleVisitLabel
            scheduleVisitDescription
        }
        contentfulPageCennik(contentful_id: { eq: "3xVAku7UMcfz74gIxZVIkJ" }, node_locale: { eq: $node_locale }) {
            currency
            procedure
            title
            pakietDziesiciuZabiegw
            pakietPiciuZabiegw
            pojedynczyZabieg
            pricingWidgetDescription {
                raw
            }
        }
        contentfulRehabilitacjaWpis(node_locale: { eq: $node_locale }, contentful_id: { eq: $id }) {
            id
            title
            titleExtended
            slug
            contentful_id
            pricing {
                ... on ContentfulCennikPozycja {
                    label
                    priceBundle
                    priceUnit
                    bundleCapacity
                    duration
                    contentful_id
                    id
                }
            }
            category
            image {
                gatsbyImageData(
                    layout: CONSTRAINED
                    cropFocus: CENTER
                    placeholder: BLURRED
                    quality: 90
                    width: 800
                    height: 400
                )
            }
            booksy
            description {
                raw
            }
        }
        allContentfulRehabilitacjaWpis(
            filter: { node_locale: { eq: $node_locale } }
            sort: { fields: title, order: ASC }
        ) {
            edges {
                node {
                    title
                    slug
                    contentful_id

                    image {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            cropFocus: CENTER
                            placeholder: BLURRED
                            quality: 90
                            width: 800
                            height: 400
                        )
                    }
                }
            }
        }
        contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
            ...contact
        }
    }
`;
